<template>
  <img
    src="../../assets/webinarLoader.svg"
    class="webinar-loader mx-auto d-block align-middle"
  />
</template>
<script>
export default {
  name: "WebinarLoader",
  components: {}
};
</script>
<style scoped>
img.webinar-loader.mx-auto.d-block {
  width: 100px;
  margin-top: 20%;
}
</style>

<template>
  <div class="class-content">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          @click="getSchedules(upcomingSchedulePage)"
          id="pills-scheduled-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-scheduled"
          type="button"
          role="tab"
          aria-controls="pills-scheduled"
          aria-selected="true"
        >
          Schedule
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          @click="getSchedules(completedSchedulePage)"
          id="pills-history-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-history"
          type="button"
          role="tab"
          aria-controls="pills-history"
          aria-selected="false"
        >
          History
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-scheduled"
        role="tabpanel"
        aria-labelledby="pills-scheduled-tab"
      >
        <div class="row">
          <webinar-loader v-if="classLoader == true"></webinar-loader>
          <ClassCard
            v-else
            v-for="(upcomingSchedule,
            upcomingScheduleIndex) in upcomingSchedules"
            :key="upcomingScheduleIndex"
            :schedule="upcomingSchedule"
            :type="upcomingSchedule"
          />
          <div
            class="empty"
            v-if="upcomingSchedules.length == 0 && classLoader == false"
          >
            {{ errorMessage }}
          </div>
        </div>
        <div class="paging-wrapper">
          <v-pagination
            v-model="upcomingSchedulePage"
            :pages="upcomingScheduleTotalPages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="upcomingScheduleChangeHandler"
            v-if="upcomingScheduleTotalClass > 0"
          />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-history"
        role="tabpanel"
        aria-labelledby="pills-history-tab"
      >
        <div class="row">
          <webinar-loader v-if="classLoader == true"></webinar-loader>
          <ClassCard
            v-else
            v-for="(completedSchedule,
            completedScheduleIndex) in completedSchedules"
            :key="completedScheduleIndex"
            :schedule="completedSchedule"
            :type="completedSchedule"
          />
          <div
            class="empty"
            v-if="completedSchedules.length == 0 && classLoader == false"
          >
            {{ errorMessage }}
          </div>
        </div>
        <div class="paging-wrapper">
          <v-pagination
            v-model="completedSchedulePage"
            :pages="completedScheduleTotalPages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="completedScheduleChangeHandler"
            v-if="completedScheduleTotalClass > 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassCard from "@/components/ClassCard.vue";
import ProgramService from "@/services/ProgramService.js";
import errorLog from "@/errorLog";
import "vue3-pagination/dist/vue3-pagination.css";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import VPagination from "vue3-pagination";

export default {
  name: "ClassTab",
  components: {
    ClassCard,
    WebinarLoader,
    VPagination
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isPupaMember"]);
      return this.$store.getters["privilege/isPupaMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isPupaMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  data() {
    return {
      upcomingSchedules: [],
      classLoader: true,
      completedSchedulePage: 1,
      upcomingSchedulePage: 1,
      completedSchedules: [],
      upcomingScheduleTotalPages: 0,
      upcomingScheduleTotalClass: 0,
      completedScheduleTotalPages: 0,
      completedScheduleTotalClass: 0,
      errorMessage: "No schedule found "
    };
  },
  created() {
    this.getSchedules(0);
  },
  methods: {
    async getSchedules(page) {
      this.classLoader = true;
      await ProgramService.getSchedules(page)
        .then(result => {
          if (result.data.status == "success") {
            this.classLoader = false;
            this.upcomingSchedules = result.data.upcoming_schedule.data;
            this.upcomingScheduleTotalPages =
              result.data.upcoming_schedule.last_page;
            this.upcomingScheduleTotalClass =
              result.data.upcoming_schedule.total;

            this.completedSchedules = result.data.completed_schedule.data;
            this.completedScheduleTotalPages =
              result.data.completed_schedule.last_page;
            this.completedScheduleTotalClass =
              result.data.completed_schedule.total;
            this.scrollTop();
          } else {
            this.classLoader = false;
            this.errorMessage = result.data.message;
          }
        })
        .catch(error => {
          this.classLoader = false;
          errorLog.log(error);
        });
    },
    upcomingScheduleChangeHandler(selectedPage) {
      this.upcomingSchedulePage = selectedPage;
      this.getSchedules(this.upcomingSchedulePage);
    },

    completedScheduleChangeHandler(selectedPage) {
      this.completedSchedulePage = selectedPage;
      this.getSchedules(this.completedSchedulePage);
    },
    scrollTop: function() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    }
  }
};
</script>

<style lang="scss">
@import "@/style/class-tab.scss";
</style>

import Http from "@/services/Http";
export default {
  getProgram(data) {
    return Http().post("program/details", data);
  },
  getSchedules(page) {
    return Http().post("program/schedules?page=" + page);
  },
  getActiveProgram() {
    return Http().post("program/active");
  },
  getRecentParticipation() {
    return Http().post("participation/recent");
  },
  getMeetingDetails(data) {
    return Http().post("meeting/details/get", data);
  },
  completeMeeting(data) {
    return Http().post("meeting/complete", data);
  },
  generateSignature(data) {
    return Http().post("signature/generate", data);
  },
  getNextSchedule(data) {
    return Http().post("/program/schedule/next", data);
  },
  getPurchaseDetails(data) {
    return Http().post("/purchase/details", data);
  },
  getFaqs(data) {
    return Http().post("/faqs", data);
  },
  getTestimonials(data) {
    return Http().post("testimonials", data);
  },
  getRecordings(data) {
    return Http().post("recordings", data);
  },
  getRecording(data) {
    return Http().post("recording", data);
  },
  downloadFile(data) {
    return Http().get("program/studyMaterial/download?file=" + data, {
      responseType: "arraybuffer"
    });
  },
  getStudyMaterials(data) {
    return Http().post("program/studyMaterial/slug", data);
  }
};

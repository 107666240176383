<template>
  <div class="col-md-4">
    <div class="class-card" v-if="type == upcomingSchedule">
      <div class="_image-wrap">
        <img :src="schedule.program_image" alt="" />
        <router-link
          :to="{ name: 'meeting', params: { id: schedule.id } }"
          class="btn cta-single"
          target="_blank"
        >
          <i class="fas fa-play"></i>
        </router-link>
        <div class="_date-box">
          {{ schedule.date }} <br />
          {{ schedule.month }}
        </div>
        <!-- <button class="btn cta-single">
                    <i class="far fa-calendar-plus"></i>
                </button> -->
      </div>
      <div class="_body-wrap">
        <h6>{{ schedule.subject_title }}</h6>
        <p><i class="far fa-clock"></i>{{ schedule.time }}</p>
        <span v-if="schedule.live_status == true"> Live</span>
      </div>
    </div>
    <div class="class-card" v-else>
      <div class="_image-wrap">
        <img :src="schedule.program_image" alt="" />
        <router-link
          :to="{ name: 'recordings', params: { id: schedule.id } }"
          class="btn cta-single"
        >
          <i class="fas fa-play"></i>
        </router-link>
        <div class="_date-box">
          {{ schedule.date }} <br />
          {{ schedule.month }}
        </div>
        <!-- <button class="btn cta-single">
                    <i class="far fa-calendar-plus"></i>
                </button> -->
      </div>
      <div class="_body-wrap">
        <h6>{{ schedule.subject_title }}</h6>
        <p><i class="far fa-clock"></i>{{ schedule.time }}</p>
        <span v-if="schedule.live_status == true"> Live</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClassCard",
  components: {},
  props: ["schedule", "type"]
};
</script>

<style lang="scss">
@import "@/style/class-card.scss";
</style>
